<template>
  <v-container class="px-lg-10">
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="orange"
          dark
          class="mb-2"
          @click="cancelButtonClick"
        >
          KEMBALI
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        sm="6"
        md="6"
      >
        <v-card
          outlined
        >
          <v-row>
            <v-col
              class="mx-3"
              style="font-size: 20px; font-weight: 500;"
            >
              {{ ticketDetails.t_title }}
            </v-col>
          </v-row>

          <v-divider class="my-1" />

          <v-row>
            <v-col
              class="mx-3 my-1"
              style="font-size: 16px;"
            >
              {{ ticketDetails.t_title_description }}
            </v-col>
          </v-row>

          <template v-if="ticketDetails.t_title_attachment.length > 0">
            <v-row>
              <v-col
                class="mx-3 my-n3"
                style="font-size: 12px;"
              >
                <i>
                  Attachments :
                </i>
              </v-col>
            </v-row>

            <v-row
              v-for="file in ticketDetails.t_title_attachment"
              :key="file.hash"
            >
              <v-col
                class="mx-3 my-n2"
                style="font-size: 12px;"
              >
                {{ file.name }}
                &nbsp;&nbsp;
                <v-icon
                  @click="iconDownloadClick(file.path)"
                >
                  mdi-arrow-down-bold-circle
                </v-icon>
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-spacer class="py-1" />
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-n2 mb-2">
      <v-col
        cols="6"
        md="3"
      >
        <div class="d-flex justify-space-around font-weight-bold text-h4 text--disabled">
          Status
        </div>
        <div class="d-flex justify-space-around font-weight-bold text-h4">
          {{ ticketDetails.t_status_string }}
        </div>
      </v-col>
      <v-col
        cols="6"
        md="3"
      >
        <div class="d-flex justify-space-around font-weight-bold text-h4 text--disabled">
          Pengaduan
        </div>
        <div class="d-flex justify-space-around font-weight-bold text-h4">
          {{ ticketDetails.t_created_time }}
        </div>
      </v-col>
    </v-row>

    <v-row
      v-for="thread in ticketDetails.t_thread"
      :key="thread.hash"
      class="my-n10"
    >
      <v-col
        sm="6"
        md="6"
      >
        <v-card
          outlined
        >
          <v-row>
            <v-col
              class="mx-3 my-0"
              style="font-size: 12px;"
            >
              {{ thread.submit_by_user_name || thread.submit_by_user_type }}
              |
              {{ thread.created_time }}
            </v-col>
          </v-row>

          <v-divider class="my-0" />

          <v-row>
            <v-col
              class="mx-3 my-1"
              style="font-size: 16px;"
            >
              {{ thread.content }}
            </v-col>
          </v-row>

          <template v-if="thread.documents.length > 0">
            <v-row>
              <v-col
                class="mx-3 my-n3"
                style="font-size: 12px;"
              >
                <i>
                  Attachments :
                </i>
              </v-col>
            </v-row>

            <v-row
              v-for="file in thread.documents"
              :key="file.hash"
            >
              <v-col
                class="mx-3 my-n2"
                style="font-size: 12px;"
              >
                {{ file.name }}
                &nbsp;&nbsp;
                <v-icon
                  @click="iconDownloadClick(file.path)"
                >
                  mdi-arrow-down-bold-circle
                </v-icon>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer class="py-1" />
            </v-row>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="isAllowModify">
      <v-row class="my-10">
        <v-col
          sm="6"
          md="6"
        >
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header><b>Balas Tanggapan</b></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea
                  v-model="replyContent"
                  solo
                  no-resize
                  color="purple"
                  label="Ketik pesan anda di sini"
                />
                <div class="d-flex">
                  <v-file-input
                    v-model="attachments"
                    class="pr-3"
                    dense
                    outlined
                    show-size
                    counter
                    label="Attachments"
                    color="purple"
                    multiple
                    @change="onChangeFileInput"
                  />
                </div>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <v-btn
                  class="my-5"
                  color="purple"
                  @click="sendButtonClick"
                >
                  KIRIMKAN
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row class="py-0">
        <v-col>
          <v-btn
            v-if="ticketDetails.t_status !== 3"
            color="primary"
            @click="closeButtonClick"
          >
            CLOSE TICKET
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      ticketDetails: {
        t_created_time: '',
        t_dept: '',
        t_hash: '',
        t_id: 1,
        t_last_update_time: '',
        t_status: 2,
        t_status_color: '',
        t_status_string: '',
        t_thread: [],
        t_title: '',
        t_title_attachment: [],
        t_title_description: '',
      },
      replyContent: '',
      attachments: [],
      attachmentsUploadedHash: [],
      loadingUpload: false,
      isAllowModify: false,
    }),

    computed: {
      // attachmentFiles () {
      //   if (this.attachmentsUploadedHash.length > 0) {
      //     const retVal = []
      //     return retVal
      //   } else {
      //     return ''
      //   }
      // },

      isLoadingUpload () {
        let retVal = false
        if (this.loadingUpload) retVal = true

        return retVal
      },
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        // Check user menus
        const userMenus = localStorage.getItem('userMenus').split(',')
        if (userMenus.includes('TICKET_MOD')) {
          this.isAllowModify = true
        }

        const vm = this

        const requestBody = {
          ticket_id: id,
        }

        axios.post('/t/ticket/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.ticketDetails = res.data.data
          }
        }).catch((e) => {
        })
      },

      // uploadButtonClick () {
      //   this.loadingUpload = true
      //   setTimeout(() => {
      //     const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
      //     const config = {
      //       headers: {
      //         'Content-Type': 'multipart/form-data',
      //       },
      //     }
      //     const requestBody = new FormData()
      //     requestBody.append('file', JSON.stringify(this.attachmentsUploadedHash))
      //     axios.post(baseCDNurl + '/g/ticket/uploadFile', requestBody, config).then((res) => {
      //       if (res.data.status === 200) {
      //         this.attachmentsUploadedHash.push(res.data.data.tmp_file_hash)
      //       }
      //     }).catch((e) => {
      //       e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
      //     })
      //     this.loadingUpload = false
      //   }, 1500)
      // },

      async sendButtonClick () {
        if (this.replyContent.trim()) {
          const requestBody = {
            ticket_id: this.$route.params.id,
            files_up: JSON.stringify(this.attachmentsUploadedHash),
            content: this.replyContent,
          }
          await axios.post('/t/ticket/reply', requestBody).then((res) => {
            if (res.data.status === 200) {
              this.$toast.success(res.data.message)
              this.attachments = []
              this.attachmentsUploadedHash = []
              this.replyContent = ''
            }
          }).catch((e) => {
            this.attachments = []
            this.attachmentsUploadedHash = []
            this.replyContent = ''
            e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
          })
        }
        this.initialize(this.$route.params.id)
      },

      onChangeFileInput (data) {
        if (this.attachments.length > 0) {
          const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
          const requestBody = new FormData()
          this.attachments.map((val, i) => {
            requestBody.append('file', this.attachments[i])
            axios.post(baseCDNurl + '/g/ticket/uploadFile', requestBody, config).then((res) => {
              if (res.data.status === 200) {
                this.attachmentsUploadedHash.push(res.data.data.tmp_file_hash)
              }
            }).catch((e) => {
              e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
            })
          })
        }
      },

      closeButtonClick () {
        const requestBody = {
          ticket_id: this.$route.params.id,
        }
        axios.post('/t/ticket/close', this.$qs.stringify(requestBody)).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
        this.initialize(this.$route.params.id)
        location.reload()
      },

      iconDownloadClick (filePath) {
        window.open(filePath)
      },

      cancelButtonClick () {
        this.$router.push({ name: 'Ticket' })
      },
    },
  }
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
